th {
  font-size: 1.5em;
  color: white;
  background-color: #FFE4C4;
}
td {
  font-size: 1.3em;
  color: white;
  background-color: #cccccc;
}

a{
  font-size: 1.5em;
  margin-top: 0.5em;
  color: black;
}

.App-clock {
  display: inline-block;
}

.redText {
  color: red;
}

.greenText {
  color: green;
}

.timeZoneName {
  padding-top: 5px;
  margin-top: 10px;
  font-size: 15px;
  padding-bottom: 3px;
  background-color: #f1f1f1;
  color: black;
}

.flex-container {
  border: none;
  display: flex;
  background-color: #f1f1f1;
  margin-top: -10px;
}

.flex-container > .selected {
  border-style: solid;
  border-width: thick;
  border-color: red;
}

.flex-container > .dayTime {
  background-color: white;
  color: black;
  width: 100px;
  margin: 8px;
  text-align: center;
  line-height: 35px;
  font-size: 20px;
}

.flex-container > .nightTime {
  background-color: grey;
  color: white;
  width: 100px;
  margin: 8px;
  text-align: center;
  line-height: 35px;
  font-size: 20px;
}

.flex-container > .cityName {
  color: black;
}

