body {
    background: url("pic_0_low.jpg") no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    color: white;
}
.container
{
    margin-top: 20%;
}
