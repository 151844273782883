.App {
  text-align: center;
  font-weight: bold
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.flex-container {
  display: flex;
  border: 1px solid green;
  justify-content: space-between;
  padding: 5px;
  margin-top: 10px;
}

.flex-item {
  margin: 5px;
}


.flex-item:first-child {

  flex: 2;
}

.flex-item:nth-child(2) {

  flex: 2;
}

.flex-item:last-child {
  background-color: beige;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
}

#remote-media video {
  width: inherit;
  height: 200px;
}